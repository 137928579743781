.App {
  text-align: center;
  vertical-align: middle;
}

#full-window {
  position: fixed;
  height: 100%;
  width: 100%;
}

.vertical-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
}