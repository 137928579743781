body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.high-z-index {
  z-index: 5;
}
.App {
  text-align: center;
  vertical-align: middle;
}

#full-window {
  position: fixed;
  height: 100%;
  width: 100%;
}

.vertical-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
}
.textarea-container {
    height: 15%;
    overflow-y: hidden;
}
.textarea-container:hover {
    height: 15%;
    overflow-y: scroll;
}

.selectStyleSuperior {
    z-index: 10;
}

.height-limit{
    max-height: 300px;
}

.box-final-mes {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.box-final-mes label {
    margin-left: 5px;
    margin-bottom: 0px !important;
}
.indicador-window {
  position: fixed;
  top: 20px;
  left: 12.5%;
  max-height: 85%;
}
.textarea-container {
    height: 15%;
    overflow-y: hidden;
}
.textarea-container:hover {
    height: 15%;
    overflow-y: scroll;
}

.selectStyle {
    z-index: 2;
}

.selectStyleSuperior {
    z-index: 10;
}

.height-limit{
    max-height: 300px;
}
.classificacao-window {
  position: fixed;
  top: 20px;
  left: 24%;
  max-height: 85%;
}
#divHints-horariosSincronizacao {
    text-align: center;
}
.height-limit{
    max-height: 100px;
}
.configuracoes-window {
  position: fixed;
  top: 50px;
  left: 24%;
  width: 75%;
  max-height: 75%;
}
.Conexoes-window {
    position: fixed;
    top: 120px;
    left: 12.5%;
    width: 75%;
    max-height: 75%;
}

.selectStyle {
    z-index: 2;
}

.colorRed {
    color:red;
}
.Logs-window {
    position: fixed;
    top: 20px;
    left: 12.5%;
    width: 75%;
    max-height: 85%;
    min-height: 85%;
}

.TableSizes{
    overflow-y: auto;
    min-height: 400px !important;
    max-height: 600px !important;
}

.selectStyle {
    z-index: 2;
}

.colorRed {
    color:red;
}

@media screen and (min-width: 840px) {
  .text-size-greater {
    font-size: 20px!important;
  }
}

@media screen and (max-width: 840px) {
  .text-size-greater {
    font-size: 12px!important;
  }
}

.color-white {
  color: white;
}

.progress {
    opacity: 0.8;
    color: 'black';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

}

.borda {
    border: 2px solid #e4e4e4 !important;
}
.selectStyle {
    z-index: 2;
}

.selectTop {
    z-index: 3
}

