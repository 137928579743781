.textarea-container {
    height: 15%;
    overflow-y: hidden;
}
.textarea-container:hover {
    height: 15%;
    overflow-y: scroll;
}

.selectStyle {
    z-index: 2;
}

.selectStyleSuperior {
    z-index: 10;
}

.height-limit{
    max-height: 300px;
}