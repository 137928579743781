.Logs-window {
    position: fixed;
    top: 20px;
    left: 12.5%;
    width: 75%;
    max-height: 85%;
    min-height: 85%;
}

.TableSizes{
    overflow-y: auto;
    min-height: 400px !important;
    max-height: 600px !important;
}

.selectStyle {
    z-index: 2;
}

.colorRed {
    color:red;
}