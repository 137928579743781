.Conexoes-window {
    position: fixed;
    top: 120px;
    left: 12.5%;
    width: 75%;
    max-height: 75%;
}

.selectStyle {
    z-index: 2;
}

.colorRed {
    color:red;
}