@media screen and (min-width: 840px) {
  .text-size-greater {
    font-size: 20px!important;
  }
}

@media screen and (max-width: 840px) {
  .text-size-greater {
    font-size: 12px!important;
  }
}

.color-white {
  color: white;
}

.progress {
    opacity: 0.8;
    color: 'black';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

}

.borda {
    border: 2px solid #e4e4e4 !important;
}