.textarea-container {
    height: 15%;
    overflow-y: hidden;
}
.textarea-container:hover {
    height: 15%;
    overflow-y: scroll;
}

.selectStyleSuperior {
    z-index: 10;
}

.height-limit{
    max-height: 300px;
}

.box-final-mes {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.box-final-mes label {
    margin-left: 5px;
    margin-bottom: 0px !important;
}